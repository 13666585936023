.preloader-main {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: transparent !important;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    /* background-image: url(../images/preloader.gif); */
    background-size: 250px;
    background-repeat: no-repeat;
    background-position: center;
}

.custom-loader {
    width: 50px;
    height: 50px;
    border: 2px solid #964b00;
    border-radius: 50%;
    display: grid;
    animation: h1 1.5s infinite linear;
    transform-origin: 50% 80%;
  }
  .custom-loader:before,
  .custom-loader:after {
    content:"";
    grid-area: 1/1;
    border:inherit;
    border-radius: 50%;
    animation: inherit;
    animation-duration: 1s;
    transform-origin: inherit;
  }
  .custom-loader:after {
    --s:-1;
  }
  
  @keyframes h1 {
     100% {transform:rotate(calc(var(--s,1)*1turn))}
  }